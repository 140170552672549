<template>
    <div class="flex space-x-2 space-y-2 flex-wrap justify-center items-baseline">
        <div v-for="filter in filters" :key="filter.id">
            <button
                type="button"
                class="inline-block px-6 py-2 border-2 border-gray-300 text-gray-800 font-semibold text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out bg-white"
                @click="filterSelected(filter)"
            >
                {{ filter.name }}
            </button>
        </div>
        <date-picker
            v-model="periodDate"
            type="date"
            :lang="lang"
            range
            placeholder="PERÍODO"
            :confirm="true"
            confirm-text="CONFIRMAR"
            format="YYYY-MM-DD HH:mm:ss"
            value-type="format"
            input-class="inline-block px-6 py-2 border-2 border-gray-300 text-gray-800 font-semibold text-xs leading-tight rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
        ></date-picker>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';
export default {
    name: 'DateFilter',
    components: { DatePicker },
    data() {
        return {
            periodDate: [],
            lang: {
                formatLocale: {
                    firstDayOfWeek: 1
                },
                monthBeforeYear: false
            },
            filters: [
                {
                    name: 'Hoje',
                    value: 0
                },
                {
                    name: '7 dias',
                    value: 7
                },
                {
                    name: '30 dias',
                    value: 30
                },
                {
                    name: '60 dias',
                    value: 60
                },
                {
                    name: 'Ano',
                    value: 365
                }
            ]
        };
    },
    watch: {
        periodDate() {
            this.setPeriod(this.periodDate);
        }
    },
    methods: {
        ...mapActions('filters', ['setPeriod']),
        filterSelected(filter) {
            this.setPeriod(filter.value);
        }
    }
};
</script>

<style>
.mx-datepicker-range {
    width: auto;
}
.mx-calendar {
    width: 333px;
    padding: 15px;
}
.mx-calendar-header,
.mx-time-header {
    height: auto;
    border-bottom: 1px solid #e2e4e5;
    margin-bottom: 8px;
    padding-bottom: 2px;
}
.mx-calendar-panel-date .mx-btn-icon-double-left,
.mx-calendar-panel-date .mx-icon-double-right {
    display: none;
}
.mx-table-date td,
.mx-table-date th {
    height: 32px;
    font-size: 14px;
    font-weight: 700;
}
.mx-table-date td,
.mx-table-date th {
    height: 32px;
    font-size: 14px;
    font-weight: 700;
}
.mx-calendar-content .cell.active {
    background-color: #958deb;
}
.mx-calendar-content .cell.hover-in-range,
.mx-calendar-content .cell.in-range {
    background-color: #e0def9;
}
.mx-datepicker-main.mx-datepicker-popup {
    position: absolute;
    border-radius: 8px;
}

.mx-datepicker-footer {
    padding: 6px 15px 15px 15px;
    text-align: right;
    border-top: none;
}
.mx-btn.mx-datepicker-btn-confirm {
    background-color: #776de6;
    color: #fff;
    text-transform: uppercase;
    padding: 12px 20px;
    border-radius: 14px;
}
</style>
