<template>
    <div class="topo pt-6 pb-12 flex flex-row content-center justify-between">
        <div class="w-80">
            <div class="border-4 mb-2 rounded-2xl w-10 flex border-gray-300"></div>
            <div class="flex">
                <!-- <button
                    class="flex items-center justify-between px-2 mr-4 border-2 border-gray-300 text-white font-semibold text-xs leading-tight uppercase rounded-full hover:bg-gray-200 focus:outline-none focus:ring-0 transition duration-150 ease-in-out text-gray-800"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                </button> -->
                <h2 class="text-2xl flex">
                    <div class="text-gray-400">{{ title }}&nbsp;</div>
                    <span class="text-gray-600 font-semibold">{{ complement }}</span>
                </h2>
            </div>
        </div>
        <div class="flex items-baseline justify-center self-end space-x-1.5" v-if="options.actions">
            <div v-for="action in actions" :key="action.id">
                <button
                    @click="action.method"
                    type="button"
                    class="inline-block px-6 py-2 border-2 border-gray-300 text-gray-800 font-semibold text-xs leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out bg-white"
                >
                    {{ action.name }}
                </button>
            </div>
        </div>
        <div class="flex items-baseline justify-center self-end" v-if="options.datefilter">
            <DateFilter />
        </div>
    </div>
</template>

<script>
import DateFilter from '@/components/DateFilter.vue';
export default {
    name: 'TopPage',
    components: {
        DateFilter
    },
    props: {
        title: {
            type: String,
            default: 'Título'
        },
        complement: {
            type: String,
            default: 'e complemento'
        },
        options: {
            type: Object,
            default: () => ({
                datefilter: true
            })
        },
        actions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    methods: {}
};
</script>

<style></style>
